<template>
  <footer>
    <div class="content">
      <div class="content_left">
        <div class="content_left_list">
          <p @click="$router.push('/')">Menu</p>
          <p @click="$router.push('/')">Home</p>
          <!-- <p @click="$router.push('/QuickApply')">Quick Apply</p> -->
          <p @click="$router.push('/ContactUs')">Contact us</p>
        </div>
      </div>
      <!--
<div class="content_right">
  <div class="download">
    <div class="box"></div>
    扫一扫下载app
  </div>
  <div class="download">
    <div class="box"></div>
    扫一扫下载app
  </div>
</div> -->
    </div>
    <div class="info">
      Copyright 2024. © All Rights Reserved by EXQUISITE SHOWERS LIMITED
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {

    };
  },
};
</script>

<style lang="scss" scoped>
@mixin pixel-ratio() {}
footer {
  @include pixel-ratio();
  position: relative;
  background-color: #202a20;
  color: #8b8b8b;
  font-size: 16px;
  .info {
    text-align: center;
    font-size: 14px;
    padding-bottom: 20px;
  }
  p {
    margin: 0;
  }
  .content {
    padding: 30px 40px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    &_left {
      flex: 6;
      &_list {
        display: inline-table;
        width: 25%;
        line-height: 44px;
        text-align: left;
        p {
          padding: 0;
          margin: 0;
          cursor: pointer;
        }
      }
      &_url {
        position: relative;
        width: 50%;
        display: inline-block;
        .url_box {
          position: absolute;
          top: 36px;
          left: 40%;
        }
        .apple_store {
          border-radius: 4px;
          // border: 1px solid rgba(255, 255, 255, 0.5);
          display: table;
          padding: 8px 0;
          font-size: 14px;
          line-height: 10px;
          margin-bottom: 10px;
          img {
            vertical-align: middle;
          }
          span {
            font-size: 12px;
          }
          div {
            display: inline-block;
            vertical-align: middle;
            margin-left: 3px;
          }
        }
        .facebook {
          margin-right: 36px;
        }
      }
      &_file {
        display: flex;
        line-height: 44px;
        margin-top: 10px;
        p {
          flex: 1;
        }
      }
    }
    &_right {
      flex: 4;
      text-align: left;
      margin-left: 20px;
      .download {
        display: inline-block;
        margin-left: 80px;
        text-align: center;
        margin-top: 20px;
        .box {
          width: 100px;
          height: 100px;
          background-color: white;
          margin-bottom: 22px;
        }
      }
    }
  }
  .content_left_file{
    a{
      color: inherit;
      text-decoration: none;
      background-color: transparent;
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 800px) {
    .content {
      display: block;
      &_left {
        &_list {
          width: 50%;
        }
        &_url {
          position: absolute;
          left: 40px;
          right: 40px;
          width: auto;
          bottom: 70px;
          .url_box {
            position: initial;
          }
        }
        &_file {
          display: block;
          padding-bottom: 80px;
        }
      }
      &_right {
        display: none;
        text-align: left;
      }
    }
  }
}
</style>
